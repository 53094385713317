<template>
  <p-form
    ref="form"
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <p-address-form
          :address="address"
          :submitting="loadingSubmit"
          :initial-loading="loadingPage"
          @cepAddress="onChangeCep"
        />

        <separator />
      </div>
    </div>

    <!-- Botões -->
    <div
      class="c-row c-horizontal-center"
      v-if="!loadingPage"
    >
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <!-- Botão Alterar -->
        <c-button
          data-cy="saveSubmitButton"
          type="submit"
          :disabled="invalid || !cepExists"
          :loading="loadingSubmit"
          class="ui-w-full ui-mb-4 sm:ui-mb-0"
        >
          Salvar alteração
        </c-button>
      </div>

      <!-- Botão Cancelar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link
          :to="{ name: 'meus-dados' }"
        >
          <c-button
            outline
            :disabled="loadingSubmit"
            class="ui-w-full"
          >
            Voltar sem salvar
          </c-button>
        </router-link>
      </div>
    </div>
  </p-form>
</template>

<script>
import { mapGetters } from 'vuex';
import PForm from '@/components/p-form';
import PAddressForm from '@/components/p-address-form';
import Separator from '@/components/separator';
import { CButton } from '@estrategiahq/coruja-web-ui';
import GenericModal from '@/modals/generic';
import { UserStoreKeys } from '@/store/modules/user';
import { GlobalStoreKeys } from '@/store/modules/global';
import { Address } from '@/models/address';

export default {
  components: {
    PForm,
    PAddressForm,
    Separator,
    CButton,
  },
  data: () => ({
    address: new Address(),
    cepExists: false,
  }),
  async mounted() {
    await this.$store.dispatch(GlobalStoreKeys.ACTION_UPDATE_PAGE_LOADING, true);
    await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);
    this.address = { ...this.loggedUser.address };
    await this.$store.dispatch(GlobalStoreKeys.ACTION_UPDATE_PAGE_LOADING, false);
  },
  computed: {
    ...mapGetters({
      loggedUser: UserStoreKeys.GET_LOGGED_USER,
      loadingSubmit: UserStoreKeys.GET_REQUEST_LOADING,
      loadingPage: GlobalStoreKeys.GET_PAGE_LOADING,
    }),
  },
  methods: {
    onChangeCep(cepAddress) {
      if (cepAddress.error) {
        this.$refs.form.setErrors(cepAddress.error);
        return;
      }

      this.cepExists = cepAddress.cepExists;
    },
    async onSubmit() {
      try {
        await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_ADDRESS, this.address);
        this.showModalSuccess();
      } catch (err) {
        this.showModalError();
      }
    },
    showModalSuccess() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'O endereço foi alterado',
          subTitle: 'Os dados serão usados como endereço de cobrança nas suas compras futuras.',
          type: 'success',
          confirmButton: {
            label: 'Ir para Meus dados',
            onClick: () => {
              this.$modal.hide();
              this.$router.push({ name: 'meus-dados' });
            },
          },
        },
      });
    },
    showModalError() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'Houve um erro no cadastro do endereço',
          subTitle: 'Confira os dados informados e tente novamente.',
          type: 'warning',
          confirmButton: {
            label: 'Entendi',
            onClick: () => this.$modal.hide(),
          },
        },
      });
    },
  },
};
</script>
