<template>
  <validation-observer ref="addressForm">
    <!-- Título -->
    <div class="c-row">
      <div class="c-col-full ui-mb-4">
        <div class="c-text-h3">
          Endereço
        </div>
        <div class="c-text-b2 c-text-gray">
          *Preenchimento obrigatório
        </div>
      </div>
    </div>

    <!-- CEP -->
    <div class="c-row">
      <validation-provider
        class="c-col-full sm:c-col-1/2"
        rules="required|length:8"
        :custom-messages="{
          required: 'O campo CEP é obrigatório',
          length: 'Inserir os 8 números do CEP'
        }"
        v-slot="{ errors }"
        vid="cep"
      >
        <p-input
          ref="cepInput"
          data-cy="cepField"
          :label="initialLoading ? '' : 'CEP*'"
          name="cep"
          v-model="address.zipCode"
          mask="00000-000"
          :disabled="submitting"
          @input="handleCep"
          @blur="handleCepBlur"
          :error-message="errors[0] || cepErrorMessage"
          :hint="cepHint"
          :icon-info="initialLoading ? null : 'info'"
          @keydown="loadingCep ? $event.preventDefault() : null"
          :loading="loadingCep"
        />
      </validation-provider>

      <div class="c-col-full sm:c-col-1/2 ui-h-input c-d-flex ui-items-center">
        <c-link
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="_blank"
          class="c-text-hyperlink c-text-gray"
        >
          Não sei meu CEP
        </c-link>
      </div>
    </div>

    <div v-show="cepExists">
      <separator />

      <!-- Rua/Avenida -->
      <div class="c-row">
        <validation-provider
          class="c-col-full ui-mb-4"
          rules="required|max:35"
          v-slot="{ errors }"
          :custom-messages="{ required: 'O campo Rua/Avenida é obrigatório' }"
          immediate
        >
          <p-input
            data-cy="streetNameField"
            label="Rua/Avenida*"
            name="streetName"
            :hint="hintLength(address.streetName, 35)"
            :maxlength="35"
            v-model.trim="address.streetName"
            :error-message="errors[0]"
            :disabled="submitting"
          />
        </validation-provider>
      </div>

      <!-- Número, Complemento e Bairro -->
      <div class="c-row">
        <validation-provider
          class="c-col-full sm:c-col-1/4 ui-mb-4"
          rules="required|max:10"
          :custom-messages="{ required: 'O campo Número é obrigatório' }"
          v-slot="{ errors }"
          name="Número"
          immediate
        >
          <p-input
            data-cy="numberField"
            label="Número*"
            name="number"
            :hint="hintLength(address.number, 10)"
            :maxlength="10"
            v-model.trim="address.number"
            :error-message="errors[0]"
            :disabled="submitting"
          />
        </validation-provider>

        <validation-provider
          class="c-col-full sm:c-col-1/4 ui-mb-4"
          rules="max:40"
          v-slot="{ errors }"
        >
          <p-input
            data-cy="complementField"
            label="Complemento"
            name="complement"
            :hint="hintLength(address.complement, 40)"
            :maxlength="40"
            v-model.trim="address.complement"
            :error-message="errors[0]"
            :disabled="submitting"
          />
        </validation-provider>

        <validation-provider
          class="c-col sm:c-col-1/2 ui-mb-4"
          rules="required|max:50"
          v-slot="{ errors }"
          :custom-messages="{ required: 'O campo Bairro é obrigatório' }"
          name="Bairro"
          immediate
        >
          <p-input
            data-cy="neighborhoodField"
            label="Bairro*"
            name="neighborhood"
            :hint="hintLength(address.neighborhood, 50)"
            :maxlength="50"
            v-model.trim="address.neighborhood"
            :error-message="errors[0]"
            :disabled="submitting"
          />
        </validation-provider>
      </div>

      <!-- Estado e Cidade -->
      <div class="c-row ui--mb-4">
        <div class="c-col-full sm:c-col-1/2 ui-mb-4">
          <p-input
            data-cy="stateField"
            label="Estado*"
            name="state"
            v-model.trim="address.state"
            icon="ArrowDown"
            :disabled="true"
          />
        </div>

        <div class="c-col-full sm:c-col-1/2 ui-mb-4">
          <p-input
            data-cy="cityField"
            label="Cidade*"
            name="city"
            v-model.trim="address.city"
            icon="ArrowDown"
            :disabled="true"
          />
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { invalidZipCodeError, requestCanceledError } from '@/utils/errors';
import PInput from '@/components/p-input';
import Separator from '@/components/separator';
import { CLink } from '@estrategiahq/coruja-web-ui';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    PInput,
    Separator,
    CLink,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    address: { type: Object, required: true },
    submitting: { type: Boolean, required: true },
    initialLoading: { type: Boolean, default: false },
  },
  data: () => ({
    loadingCep: false,
    pristine: true,
    cepExists: false,
    cepErrorMessage: '',
  }),
  computed: {
    cepHint() {
      if (this.initialLoading) return null;
      if (this.loadingCep) return 'Pesquisando...';
      return 'Inserir os 8 números do CEP';
    },
  },
  mounted() {
    if (this.address.zipCode) {
      this.handleCep();
    }
  },
  methods: {
    hintLength(fieldValue = '', max = 256) {
      const currentLength = fieldValue ? fieldValue.length : 0;
      return `Caracteres ${currentLength}/${max}`;
    },
    handleCepBlur() {
      if (!this.cepExists && !this.loadingCep) {
        this.$refs.cepInput.setErrorState();
      }
    },
    setAddressOnLoad(address) {
      this.address.city = address.city;
      this.address.state = address.state;
      if (!this.address.streetName) this.address.streetName = address.streetName;
      if (!this.address.neighborhood) this.address.neighborhood = address.neighborhood;
      this.pristine = false;
    },
    setAddress(address) {
      if (this.pristine) return this.setAddressOnLoad(address);
      this.address.city = address.city;
      this.address.state = address.state;
      this.address.streetName = address.streetName;
      this.address.neighborhood = address.neighborhood;
      this.address.complement = '';
      this.address.number = '';
    },
    async handleCep(zipCode) {
      if (this.loadingCep) {
        return;
      }

      if (!zipCode || zipCode.length < 8) {
        return;
      }

      this.loadingCep = true;
      this.cepExists = false;

      this.$services.accounts.getAddressByZipCode(zipCode)
        .then(async address => {
          if (!await this.$refs.addressForm.validate({ silent: true })) {
            this.$refs.cepInput.setSuccessState();
          }
          this.setAddress(address);
          this.setCepExists(true);
          this.cepErrorMessage = '';
        })
        .catch(e => {
          if (e === requestCanceledError) {
            return;
          }

          this.cepErrorMessage = 'Ocorreu um erro. Tente novamente';
          if (e === invalidZipCodeError) {
            this.cepErrorMessage = 'CEP não encontrado';
          }

          this.$refs.cepInput.setErrorState();
          this.setCepExists(false);
        })
        .finally(() => {
          this.loadingCep = false;
        });
    },
    async setCepExists(cepExists) {
      this.cepExists = cepExists;
      this.$emit('cepAddress', { cepExists });
    },
  },
  beforeDestroy() {
    this.$services.accounts.cancelRequests();
  },
};
</script>
